import React from "react";

function Privacypolicy({ history }) {
  const isMobile = new URLSearchParams(window.location.search).get("mobile") === "true";
  return (
    <div
      style={{
        marginBottom: "10px",
        marginTop: "10px",
        marginLeft: "10px",
        marginRight: "10px",
        textAlign: "left",
        justifyContent: "left",
        textJustify: "center",
        padding: "0px 20px",
      }}
    >
      {!isMobile && <h1 tabIndex={0}>Privacy Policy</h1>}
      <p tabIndex={0}>
        Last updated: September 25, 2020 This Privacy Policy describes Our
        policies and procedures on the collection, use and disclosure of Your
        information when You use the Service and tells You about Your privacy
        rights and how the law protects You. We use Your Personal data to
        provide and improve the Service. By using the Service, You agree to the
        collection and use of information in accordance with this Privacy
        Policy.
      </p>
      <h2 tabIndex={0}>Interpretation and Definitions</h2>
      <h4 tabIndex={0}>Interpretation</h4>
      <p tabIndex={0}>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <h4 tabIndex={0}>Definitions</h4>
      <p tabIndex={0}>
        For the purposes of this Privacy Policy: Account means a unique account
        created for You to access our Service or parts of our Service. Affiliate
        means an entity that controls, is controlled by or is under common
        control with a party, where "control" means ownership of 50% or more of
        the shares, equity interest or other securities entitled to vote for
        election of directors or other managing authority. Application means the
        software program provided by the Company downloaded by You on any
        electronic device, named ReVision Fitness App Business, for the purpose
        of the CCPA (California Consumer Privacy Act), refers to the Company as
        the legal entity that collects Consumers' personal information and
        determines the purposes and means of the processing of Consumers'
        personal information, or on behalf of which such information is
        collected and that alone, or jointly with others, determines the
        purposes and means of the processing of consumers' personal information,
        that does business in the State of California. Company (referred to as
        either "the Company", "We", "Us" or "Our" in this Agreement) refers to
        ReVision Training LLC, 506 Constance Ave, Fort Wayne, IN 46805. For the
        purpose of the GDPR, the Company is the Data Controller. Consumer, for
        the purpose of the CCPA (California Consumer Privacy Act), means a
        natural person who is a California resident. A resident, as defined in
        the law, includes (1) every individual who is in the USA for other than
        a temporary or transitory purpose, and (2) every individual who is
        domiciled in the USA who is outside the USA for a temporary or
        transitory purpose. Cookies are small files that are placed on Your
        computer, mobile device or any other device by a website, containing the
        details of Your browsing history on that website among its many uses.
        Country refers to: Indiana, United States Data Controller, for the
        purposes of the GDPR (General Data Protection Regulation), refers to the
        Company as the legal person which alone or jointly with others
        determines the purposes and means of the processing of Personal Data.
        Device means any device that can access the Service such as a computer,
        a cellphone or a digital tablet. Personal Data is any information that
        relates to an identified or identifiable individual. For the purposes
        for GDPR, Personal Data means any information relating to You such as a
        name, an identification number, location data, online identifier or to
        one or more factors specific to the physical, physiological, genetic,
        mental, economic, cultural or social identity. For the purposes of the
        CCPA, Personal Data means any information that identifies, relates to,
        describes or is capable of being associated with, or could reasonably be
        linked, directly or indirectly, with You. Sale, for the purpose of the
        CCPA (California Consumer Privacy Act), means selling, renting,
        releasing, disclosing, disseminating, making available, transferring, or
        otherwise communicating orally, in writing, or by electronic or other
        means, a Consumer's Personal information to another business or a third
        party for monetary or other valuable consideration. Service refers to
        the Application or the Website or both. Service Provider means any
        natural or legal person who processes the data on behalf of the Company.
        It refers to third-party companies or individuals employed by the
        Company to facilitate the Service, to provide the Service on behalf of
        the Company, to perform services related to the Service or to assist the
        Company in analyzing how the Service is used. For the purpose of the
        GDPR, Service Providers are considered Data Processors. Third-party
        Social Media Service refers to any website or any social network website
        through which a User can log in or create an account to use the Service.
        Usage Data refers to data collected automatically, either generated by
        the use of the Service or from the Service infrastructure itself (for
        example, the duration of a page visit). Website refers to The ReVision
        Fitness Program, accessible from "www.revisionfitnessapp.com" You means
        the individual accessing or using the Service, or the company, or other
        legal entity on behalf of which such individual is accessing or using
        the Service, as applicable. Under GDPR (General Data Protection
        Regulation), You can be referred to as the Data Subject or as the User
        as you are the individual using the Service.
      </p>
      <h2 tabIndex={0}>Collecting and Using Your Personal Data</h2>
      <h3 tabIndex={0}>Types of Data Collected</h3>
      <h4 tabIndex={0}>Personal Data</h4>
      <p tabIndex={0}>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </p>
      <p tabIndex={0}>Email address</p>
      <p tabIndex={0}>First name and last name</p>
      <p tabIndex={0}>Phone number</p>
      <p tabIndex={0}>Usage Data</p>
      <h4 tabIndex={0}>Usage Data</h4>
      <p tabIndex={0}>
        Usage Data is collected automatically when using the Service. Usage Data
        may include information such as Your Device's Internet Protocol address
        (e.g. IP address), browser type, browser version, the pages of our
        Service that You visit, the time and date of Your visit, the time spent
        on those pages, unique device identifiers and other diagnostic data.
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data. We may also collect information that Your browser sends
        whenever You visit our Service or when You access the Service by or
        through a mobile device.
      </p>
      <h4 tabIndex={0}>Tracking Technologies and Cookies</h4>
      <p tabIndex={0}>
        We use Cookies and similar tracking technologies to track the activity
        on Our Service and store certain information. Tracking technologies used
        are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service. You can instruct Your browser to refuse
        all Cookies or to indicate when a Cookie is being sent. However, if You
        do not accept Cookies, You may not be able to use some parts of our
        Service. Cookies can be "Persistent" or "Session" Cookies. Persistent
        Cookies remain on your personal computer or mobile device when You go
        offline, while Session Cookies are deleted as soon as You close your web
        browser. Learn more about cookies: All About Cookies. We use both
        session and persistent Cookies for the purposes set out below:
      </p>
      <h5 tabIndex={0}>Necessary / Essential Cookies</h5>
      <p tabIndex={0}>Type: Session Cookies</p>
      <p tabIndex={0}>Administered by: Us</p>
      <p tabIndex={0}>
        Purpose: These Cookies are essential to provide You with services
        available through the Website and to enable You to use some of its
        features. They help to authenticate users and prevent fraudulent use of
        user accounts. Without these Cookies, the services that You have asked
        for cannot be provided, and We only use these Cookies to provide You
        with those services.
      </p>
      <h5 tabIndex={0}>Cookies Policy / Notice Acceptance Cookies</h5>
      <p tabIndex={0}>Type: Persistent Cookies</p>
      <p tabIndex={0}>Administered by: Us</p>
      <p tabIndex={0}>
        Purpose: These Cookies identify if users have accepted the use of
        cookies on the Website.
      </p>{" "}
      <h5 tabIndex={0}>Functionality Cookies</h5>
      <p tabIndex={0}>Type: Session Cookies</p>
      <p tabIndex={0}>Administered by: Us</p>
      <p tabIndex={0}>
        Purpose: These Cookies allow us to remember choices You make when You
        use the Website, such as remembering your login details or language
        preference. The purpose of these Cookies is to provide You with a more
        personal experience and to avoid You having to re-enter your preferences
        every time You use the Website.
      </p>
      <p tabIndex={0}>
        For more information about the cookies we use and your choices regarding
        cookies, please visit our Cookies Policy or the Cookies section of our
        Privacy Policy.
      </p>
      <h4 tabIndex={0}>Use of Your Personal Data</h4>
      <p tabIndex={0}>
        The Company may use Personal Data for the following purposes:
      </p>
      <ul tabIndex={0}>
        <li tabIndex={0}>
          <b>To provide and maintain our Service</b>, including to monitor the
          usage of our Service.
        </li>
        <li tabIndex={0}>
          <b>To manage Your Account:</b> to manage Your registration as a user
          of the Service. The Personal Data You provide can give You access to
          different functionalities of the Service that are available to You as
          a registered user.
        </li>
        <li tabIndex={0}>
          For the performance of a contract: the development, compliance and
          undertaking of the purchase contract for the products, items or
          services You have purchased or of any other contract with Us through
          the Service.
        </li>
        <li tabIndex={0}>
          To contact You: To contact You by email, telephone calls, SMS, or
          other equivalent forms of electronic communication, such as a mobile
          application's push notifications regarding updates or informative
          communications related to the functionalities, products or contracted
          services, including the security updates, when necessary or reasonable
          for their implementation.
        </li>
        <li tabIndex={0}>
          To provide You with news, special offers and general information about
          other goods, services and events which we offer that are similar to
          those that you have already purchased or enquired about unless You
          have opted not to receive such information.
        </li>
        <li tabIndex={0}>
          To manage Your requests: To attend and manage Your requests to Us.
        </li>
      </ul>
      <p tabIndex={0}>
        We may share your personal information in the following situations:
      </p>
      <ul tabIndex={0}>
        <li tabIndex={0}>
          <b>With Service Providers</b>: We may share Your personal information
          with Service Providers to monitor and analyze the use of our Service,
          for payment processing, to contact You.
        </li>
        <li tabIndex={0}>
          <b>For Business transfers</b>: We may share or transfer Your personal
          information in connection with, or during negotiations of, any merger,
          sale of Company assets, financing, or acquisition of all or a portion
          of our business to another company.
        </li>
        <li tabIndex={0}>
          <b>With Affiliates </b>: We may share Your information with Our
          affiliates, in which case we will require those affiliates to honor
          this Privacy Policy. Affiliates include Our parent company and any
          other subsidiaries, joint venture partners or other companies that We
          control or that are under common control with Us.
        </li>
        <li tabIndex={0}>
          <b>With Business partners</b>: We may share Your information with Our
          business partners to offer You certain products, services or
          promotions.
        </li>
        <li tabIndex={0}>
          <b>With other users</b>: when You share personal information or
          otherwise interact in the public areas with other users, such
          information may be viewed by all users and may be publicly distributed
          outside. If You interact with other users or register through a
          Third-Party Social Media Service, Your contacts on the Third-Party
          Social Media Service may see Your name, profile, pictures and
          description of Your activity. Similarly, other users will be able to
          view descriptions of Your activity, communicate with You and view Your
          profile.
        </li>
      </ul>
      <h3 tabIndex={0}>Retention of Your Personal Data</h3>
      <p tabIndex={0}>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies. The Company will also retain Usage Data
        for internal analysis purposes. Usage Data is generally retained for a
        shorter period of time, except when this data is used to strengthen the
        security or to improve the functionality of Our Service, or We are
        legally obligated to retain this data for longer time periods.
      </p>
      <h3 tabIndex={0}>Transfer of Your Personal Data</h3>
      <p tabIndex={0}>
        Your information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction. Your
        consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer. The Company will
        take all steps reasonably necessary to ensure that Your data is treated
        securely and in accordance with this Privacy Policy and no transfer of
        Your Personal Data will take place to an organization or a country
        unless there are adequate controls in place including the security of
        Your data and other personal information.
      </p>
      <h3 tabIndex={0}>Disclosure of Your Personal Data</h3>
      <h4 tabIndex={0}>Business Transactions</h4>
      <p tabIndex={0}>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </p>
      <h4 tabIndex={0}>Law enforcement</h4>
      <p tabIndex={0}>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </p>
      <h4 tabIndex={0}>Other legal requirements</h4>
      <p tabIndex={0}>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
        <ul>
          <li tabIndex={0}>Comply with a legal obligation</li>
          <li tabIndex={0}>
            Protect and defend the rights or property of the Company
          </li>
          <li tabIndex={0}>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li tabIndex={0}>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li tabIndex={0}>Protect against legal liability</li>
        </ul>
      </p>
      <h4 tabIndex={0}>Security of Your Personal Data</h4>
      <p tabIndex={0}>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </p>
      <h2 tabIndex={0}>
        Detailed Information on the Processing of Your Personal Data
      </h2>
      <p tabIndex={0}>
        Service Providers have access to Your Personal Data only to perform
        their tasks on Our behalf and are obligated not to disclose or use it
        for any other purpose.
      </p>
      <h3 tabIndex={0}>Payments</h3>
      <p tabIndex={0}>
        We may provide paid products and/or services within the Service. In that
        case, we may use third-party services for payment processing (e.g.
        payment processors). We will not store or collect Your payment card
        details. That information is provided directly to Our third-party
        payment processors whose use of Your personal information is governed by
        their Privacy Policy. These payment processors adhere to the standards
        set by PCI-DSS as managed by the PCI Security Standards Council, which
        is a joint effort of brands like Visa, Mastercard, American Express and
        Discover. PCI-DSS requirements help ensure the secure handling of
        payment information. Stripe Their Privacy Policy can be viewed at
        <a href="https://stripe.com/us/privacy">
          https://stripe.com/us/privacy
        </a>
      </p>
      <h2 tabIndex={0}>GDPR Privacy</h2>
      <h3 tabIndex={0}>Legal Basis for Processing Personal Data under GDPR</h3>
      <p tabIndex={0}>
        We may process Personal Data under the following conditions:
        <ul>
          <li tabIndex={0}>
            <b>Consent:</b> You have given Your consent for processing Personal
            Data for one or more specific purposes.
          </li>
          <li tabIndex={0}>
            <b>Performance of a contract:</b> Provision of Personal Data is
            necessary for the performance of an agreement with You and/or for
            any pre-contractual obligations thereof.
          </li>
          <li tabIndex={0}>
            <b> Legal obligations:</b> Processing Personal Data is necessary for
            compliance with a legal obligation to which the Company is subject.
          </li>
          <li tabIndex={0}>
            <b>Vital interests:</b> Processing Personal Data is necessary in
            order to protect Your vital interests or of another natural person.
          </li>
          <li tabIndex={0}>
            <b>Public interests:</b> Processing Personal Data is related to a
            task that is carried out in the public interest or in the exercise
            of official authority vested in the Company.
          </li>
          <li tabIndex={0}>
            <b>Legitimate interests:</b> Processing Personal Data is necessary
            for the purposes of the legitimate interests pursued by the Company.
          </li>
        </ul>
        In any case, the Company will gladly help to clarify the specific legal
        basis that applies to the processing, and in particular whether the
        provision of Personal Data is a statutory or contractual requirement, or
        a requirement necessary to enter into a contract.
      </p>
      <h3 tabIndex={0}>Your Rights under the GDPR</h3>
      <p tabIndex={0}>
        The Company undertakes to respect the confidentiality of Your Personal
        Data and to guarantee You can exercise Your rights.
      </p>
      <p tabIndex={0}>
        {" "}
        You have the right under this Privacy Policy, and by law if You are
        within the EU, to:
        <ul>
          <li tabIndex={0}>
            <b>Request access to Your Personal Data.</b> The right to access,
            update or delete the information We have on You. Whenever made
            possible, you can access, update or request deletion of Your
            Personal Data directly within Your account settings section. If you
            are unable to perform these actions yourself, please contact Us to
            assist You. This also enables You to receive a copy of the Personal
            Data We hold about You.
          </li>
          <li tabIndex={0}>
            <b>
              Request correction of the Personal Data that We hold about You.
            </b>{" "}
            You have the right to to have any incomplete or inaccurate
            information We hold about You corrected.
          </li>
          <li tabIndex={0}>
            <b>Object to processing of Your Personal Data.</b> This right exists
            where We are relying on a legitimate interest as the legal basis for
            Our processing and there is something about Your particular
            situation, which makes You want to object to our processing of Your
            Personal Data on this ground. You also have the right to object
            where We are processing Your Personal Data for direct marketing
            purposes.
          </li>
          <li tabIndex={0}>
            <b>Request erasure of Your Personal Data.</b> You have the right to
            ask Us to delete or remove Personal Data when there is no good
            reason for Us to continue processing it.
          </li>
          <li tabIndex={0}>
            <b>Request the transfer of Your Personal Data.</b> We will provide
            to You, or to a third-party You have chosen, Your Personal Data in a
            structured, commonly used, machine-readable format. Please note that
            this right only applies to automated information which You initially
            provided consent for Us to use or where We used the information to
            perform a contract with You.
          </li>
          <li tabIndex={0}>
            <b>Withdraw Your consent.</b> You have the right to withdraw Your
            consent on using your Personal Data. If You withdraw Your consent,
            We may not be able to provide You with access to certain specific
            functionalities of the Service.
          </li>
        </ul>
      </p>
      <h3 tabIndex={0}>Exercising of Your GDPR Data Protection Rights</h3>
      <p tabIndex={0}>
        You may exercise Your rights of access, rectification, cancellation and
        opposition by contacting Us. Please note that we may ask You to verify
        Your identity before responding to such requests. If You make a request,
        We will try our best to respond to You as soon as possible. You have the
        right to complain to a Data Protection Authority about Our collection
        and use of Your Personal Data. For more information, if You are in the
        European Economic Area (EEA), please contact Your local data protection
        authority in the EEA.
      </p>
      <h2 tabIndex={0}>CCPA Privacy</h2>
      <h3 tabIndex={0}>Your Rights under the CCPA</h3>
      <p tabIndex={0}>
        Under this Privacy Policy, and by law if You are a resident of
        California, You have the following rights:
        <ul>
          <li tabIndex={0}>
            <b>The right to notice.</b> You must be properly notified which
            categories of Personal Data are being collected and the purposes for
            which the Personal Data is being used.
          </li>
          <li tabIndex={0}>
            <b>The right to access / the right to request.</b> The CCPA permits
            You to request and obtain from the Company information regarding the
            disclosure of Your Personal Data that has been collected in the past
            12 months by the Company or its subsidiaries to a third-party for
            the third party's direct marketing purposes.
          </li>
          <li tabIndex={0}>
            <b>The right to say no to the sale of Personal Data.</b> You also
            have the right to ask the Company not to sell Your Personal Data to
            third parties. You can submit such a request by visiting our "Do Not
            Sell My Personal Information" section or web page.
          </li>
          <li tabIndex={0}>
            <b>The right to know about Your Personal Data.</b> You have the
            right to request and obtain from the Company information regarding
            the disclosure of the following:{" "}
            <ul>
              <li tabIndex={0}>The categories of Personal Data collected</li>
              <li tabIndex={0}>
                The sources from which the Personal Data was collected
              </li>
              <li tabIndex={0}>
                The business or commercial purpose for collecting or selling the
                Personal Data
              </li>
              <li tabIndex={0}>
                Categories of third parties with whom We share Personal Data
              </li>
              <li tabIndex={0}>
                The specific pieces of Personal Data we collected about You
              </li>
            </ul>
          </li>
          <li tabIndex={0}>
            <b>The right to delete Personal Data.</b> You also have the right to
            request the deletion of Your Personal Data that have been collected
            in the past 12 months.
          </li>
          <li tabIndex={0}>
            <b>The right not to be discriminated against.</b> You have the right
            not to be discriminated against for exercising any of Your
            Consumer's rights, including by:{" "}
            <ul>
              <li tabIndex={0}>Denying goods or services to You</li>
              <li tabIndex={0}>
                Charging different prices or rates for goods or services,
                including the use of discounts or other benefits or imposing
                penalties
              </li>
              <li tabIndex={0}>
                Providing a different level or quality of goods or services to
                You
              </li>
              <li tabIndex={0}>
                Suggesting that You will receive a different price or rate for
                goods or services or a different level or quality of goods or
                services.
              </li>
            </ul>
          </li>
        </ul>
      </p>
      <h3 tabIndex={0}>Exercising Your CCPA Data Protection Rights</h3>
      <p tabIndex={0}>
        In order to exercise any of Your rights under the CCPA, and if you are a
        California resident, You can email or call us or visit our "Do Not Sell
        My Personal Information" section or web page.
      </p>
      <p tabIndex={0}>
        The Company will disclose and deliver the required information free of
        charge within 45 days of receiving Your verifiable request. The time
        period to provide the required information may be extended once by an
        additional 45 days when reasonable necessary and with prior notice
      </p>
      <h3 tabIndex={0}>Do Not Sell My Personal Information</h3>
      <p tabIndex={0}>
        We do not sell personal information. However, the Service Providers we
        partner with (for example, our advertising partners) may use technology
        on the Service that "sells" personal information as defined by the CCPA
        law.
      </p>
      <p tabIndex={0}>
        If you wish to opt out of the use of your personal information for
        interest-based advertising purposes and these potential sales as defined
        under CCPA law, you may do so by following the instructions below.
      </p>
      <p tabIndex={0}>
        Please note that any opt out is specific to the browser You use. You may
        need to opt out on every browser that you use.
      </p>
      <h4 tabIndex={0}>Website</h4>
      <p tabIndex={0}>
        You can opt out of receiving ads that are personalized as served by our
        Service Providers by following our instructions presented on the
        Service:
        <ul>
          <li tabIndex={0}>From Our "Cookie Consent" notice banner</li>
          <li tabIndex={0}>Or from Our "CCPA Opt-out" notice banner</li>
          <li tabIndex={0}>
            Or from Our "Do Not Sell My Personal Information" notice banner
          </li>
          <li tabIndex={0}>
            Or from Our "Do Not Sell My Personal Information" link
          </li>
        </ul>
        The opt out will place a cookie on Your computer that is unique to the
        browser You use to opt out. If you change browsers or delete the cookies
        saved by your browser, you will need to opt out again.
      </p>
      <h4 tabIndex={0}>Mobile Devices</h4>
      <p tabIndex={0}>
        Your mobile device may give you the ability to opt out of the use of
        information about the apps you use in order to serve you ads that are
        targeted to your interests:
        <ul>
          <li tabIndex={0}>
            "Opt out of Interest-Based Ads" or "Opt out of Ads Personalization"
            on Android devices
          </li>
          <li tabIndex={0}>"Limit Ad Tracking" on iOS devices</li>
        </ul>
        You can also stop the collection of location information from Your
        mobile device by changing the preferences on your mobile device.
      </p>
      <h2 tabIndex={0}>
        Your California Privacy Rights (California's Shine the Light law)
      </h2>
      <p tabIndex={0}>
        Under California Civil Code Section 1798 (California's Shine the Light
        law), California residents with an established business relationship
        with us can request information once a year about sharing their Personal
        Data with third parties for the third parties' direct marketing
        purposes. If you'd like to request more information under the California
        Shine the Light law, and if you are a California resident, You can
        contact Us using the contact information provided below.
      </p>
      <h2 tabIndex={0}>
        California Privacy Rights for Minor Users (California Business and
        Professions Code Section 22581)
      </h2>
      <p tabIndex={0}>
        California Business and Professions Code section 22581 allow California
        residents under the age of 18 who are registered users of online sites,
        services or applications to request and obtain removal of content or
        information they have publicly posted. To request removal of such data,
        and if you are a California resident, You can contact Us using the
        contact information provided below, and include the email address
        associated with Your account. Be aware that Your request does not
        guarantee complete or comprehensive removal of content or information
        posted online and that the law may not permit or require removal in
        certain circumstances.
      </p>
      <h2 tabIndex={0}>Links to Other Websites</h2>
      <p tabIndex={0}>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy
        of every site You visit. We have no control over and assume no
        responsibility for the content, privacy policies or practices of any
        third party sites or services.
      </p>
      <h2 tabIndex={0}>Changes to this Privacy Policy</h2>
      <p tabIndex={0}>
        We may update our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page. We will
        let You know via email and/or a prominent notice on Our Service, prior
        to the change becoming effective and update the "Last updated" date at
        the top of this Privacy Policy. You are advised to review this Privacy
        Policy periodically for any changes. Changes to this Privacy Policy are
        effective when they are posted on this page.
      </p>
      <h2 tabIndex={0}>Contact Us</h2>
      <p tabIndex={0}>
        If you have any questions about this Privacy Policy, You can contact us:
        <ul>
          <li tabIndex={0}>By email: tylermerren@revisionfitnessapp.com</li>
        </ul>
      </p>
      {!isMobile && <button
        className="btn btn-primary"
        onClick={() => {
          history.goBack();
        }}
      >
        Back
      </button>}{" "}
    </div>
  );
}

export default Privacypolicy;
